'use client'

import { useRef } from 'react'

import { type RootStoreProps, useRootStore } from '@/lib/stores/root'

/**
 * This component makes data available inside client components without the need for prop drilling.
 * E. g. when setting the googleMapsApiKey in the root layout file, client components have access to this value.
 */
const RootStoreInitializer = ({ googleMapsApiKey, googleMapsMapId }: RootStoreProps): null => {
  const isInitialized = useRef(false)

  if (!isInitialized.current) {
    useRootStore.setState({
      googleMapsApiKey,
      googleMapsMapId
    })

    isInitialized.current = true
  }

  return null
}

export default RootStoreInitializer
