import { create } from 'zustand'

import { type env } from '@/env/server'

export interface RootStoreProps {
  googleMapsApiKey: typeof env.GOOGLE_MAPS_KEY
  googleMapsMapId: typeof env.GOOGLE_MAPS_MAP_ID
}

/**
 * This store contains non-sensitive data that can be used inside server and client components without prop drilling.
 */
export const useRootStore = create<RootStoreProps>(set => ({
  googleMapsApiKey: '',
  googleMapsMapId: ''
}))
