'use client'

import { type AbstractIntlMessages, NextIntlClientProvider } from 'next-intl'
import { type ReactNode } from 'react'

interface Props {
  messages: AbstractIntlMessages
  locale: string
  children: ReactNode
  now: Date
  timeZone: string
}

const NextIntlProvider = ({ messages, locale, children, now, timeZone }: Props): JSX.Element => {
  return (
    <NextIntlClientProvider
      defaultTranslationValues={{
        i: text => <i>{text}</i>,
        nowrap: text => <span className="whitespace-nowrap">{text}</span>,
        strong: text => <strong>{text}</strong>
      }}
      locale={locale}
      messages={messages}
      now={now}
      timeZone={timeZone}
    >
      {children}
    </NextIntlClientProvider>
  )
}

export default NextIntlProvider
