'use client'

import { type Toast, toast, ToastBar, Toaster as HotToaster } from 'react-hot-toast'

import { Icon } from '../Icon'

type ValueFunction<TValue, TArg> = (arg: TArg) => TValue
type ValueOrFunction<TValue, TArg> = TValue | ValueFunction<TValue, TArg>
type Renderable = JSX.Element | string | null
type Message = ValueOrFunction<Renderable, Toast>
interface DebounceLoadingProps {
  promise: Promise<any>
  options: {
    loading: Message
    success: Message
    error: Message
  }
}

const MIN_LOADING_VISIBILITY = 2000
const LOADING_DELAY = 400

/**
 * Contains a list of toastIds that are manually cancelled by clicking the close button inside a toast notification.
 * This allows us to prevent showing an unwanted success/error state of a given toast instance after the loading state has been dismissed.
 */
let cancelledToastIds: string[] = []

export const toastPromiseWithDelay = ({
  promise,
  options: { success, loading, error }
}: DebounceLoadingProps): void => {
  let toastId: string | undefined
  let timeSinceLoadingAppeared: number

  const timer = setTimeout(() => {
    toastId = toast.loading(loading, { duration: Number.POSITIVE_INFINITY })
    timeSinceLoadingAppeared = new Date().getTime()
  }, LOADING_DELAY)

  promise
    .then(() => {
      if (toastId != null) {
        if (!cancelledToastIds.includes(toastId)) {
          if (new Date().getTime() - timeSinceLoadingAppeared > MIN_LOADING_VISIBILITY) {
            toast.success(success, { id: toastId, duration: 2000 })
          } else {
            setTimeout(
              () => {
                toast.success(success, { id: toastId, duration: 2000 })
              },
              MIN_LOADING_VISIBILITY - (new Date().getTime() - timeSinceLoadingAppeared)
            )
          }
        } else {
          cancelledToastIds = cancelledToastIds.filter(value => value !== toastId)
        }
      } else {
        toast.success(success)
      }
    })
    .catch(() => {
      if (toastId != null) {
        if (!cancelledToastIds.includes(toastId)) {
          if (new Date().getTime() - timeSinceLoadingAppeared > MIN_LOADING_VISIBILITY) {
            toast.error(error, { id: toastId, duration: 2000 })
          } else {
            setTimeout(
              () => {
                toast.error(error, { id: toastId, duration: 2000 })
              },
              MIN_LOADING_VISIBILITY - (new Date().getTime() - timeSinceLoadingAppeared)
            )
          }
        } else {
          cancelledToastIds = cancelledToastIds.filter(value => value !== toastId)
        }
      } else {
        toast.error(error)
      }
    })
    .finally(() => {
      clearTimeout(timer)
    })
}

const Toaster = (): React.JSX.Element => {
  const renderIcon = (t: Toast): React.JSX.Element => {
    switch (t.type) {
      case 'error':
        return <Icon className="text-error" src="GeneralWarning" />
      case 'loading':
        return <Icon className="text-primary" src="Clock" />
      case 'success':
        return <Icon className="text-success" src="CircleCheck" />

      default:
        return <></>
    }
  }
  return (
    <HotToaster position="bottom-right">
      {t => (
        <ToastBar
          position="bottom-right"
          style={{
            padding: '16px 32px 16px 16px',
            position: 'relative'
          }}
          toast={t}
        >
          {({ icon, message }) => {
            return (
              <>
                <div className="flex items-center gap-16">
                  {renderIcon(t)}
                  <span className="mx-[-10px] my-[-4px] text-14">{message}</span>
                </div>
                <button
                  className="absolute right-8 top-8 opacity-30 transition-opacity hover:opacity-100"
                  onClick={() => {
                    cancelledToastIds.push(t.id)
                    toast.dismiss(t.id)
                  }}
                >
                  <Icon height={12} src="Close" width={12} />
                </button>
              </>
            )
          }}
        </ToastBar>
      )}
    </HotToaster>
  )
}

export default Toaster
