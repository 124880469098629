'use client'

import { useSearchParams } from 'next/navigation'
import { useEffect } from 'react'

import { usePathname } from '@/lib/i18n/navigation'

const HydrationCheck = (): JSX.Element => {
  const pathname = usePathname()
  const query = useSearchParams()

  useEffect(() => {
    document.querySelector('body')?.setAttribute('data-hydrated', '')
  }, [pathname, query])

  return <></>
}

export default HydrationCheck
